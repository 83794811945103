/**===== spinner5 =====*/
$border-color: #03254c;
$box-shadow: skyblue;
$spinner-inner-color: #289df4;
$spinner-outer-color: #1167b1;

.spinner5 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    border: 5px solid transparent;
    border-top-color: $border-color;
    border-bottom-color: $border-color;
    box-shadow: 0 0 10px $box-shadow;
    border-radius: 50%;
    -webkit-animation: spinner5 .8s linear infinite;
            animation: spinner5 .8s linear infinite;
  }
  
  .spinner5:after {
    content: "";
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border: 5px solid transparent;
    border-radius: 50%;
    background: $spinner-inner-color;
    -webkit-animation: spinner5_after 1s linear infinite;
            animation: spinner5_after 1s linear infinite;
  }
  
  @keyframes spinner5 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spinner5_after {
    0% {
      border-top: 5px solid $spinner-outer-color;
      border-bottom: 5px solid $spinner-outer-color;
    }
    50% {
      border-top: 5px solid $spinner-outer-color;
      border-bottom: 5px solid $spinner-outer-color;
    }
    100% {
      border-top: 5px solid $spinner-outer-color;
      border-bottom: 5px solid $spinner-outer-color;
    }
  }
  @-webkit-keyframes spinner5 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spinner5_after {
    0% {
      border-top: 5px solid $spinner-outer-color;
      border-bottom: 5px solid $spinner-outer-color;
    }
    50% {
      border-top: 5px solid $spinner-outer-color;
      border-bottom: 5px solid $spinner-outer-color;
    }
    100% {
      border-top: 5px solid $spinner-outer-color;
      border-bottom: 5px solid $spinner-outer-color;
    }
  }

